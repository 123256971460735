<template>
  <div>
    <div class="link-anchor" ref="contact">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    idKey: String
  },
  methods: {
    scrollIntoView () {
      this.$nextTick(function () {
        if (this.$route.hash === this.idKey) {
          let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
          if (width > 800 || navigator.userAgent.match(/Android|webOS|iPhone|iPod|Blackberry/i) === null) {
            // Desktop
            this.$scrollmagic.scrollTo(this.$refs.contact)
          } else {
            // Mobile.
            const mobileReferenceElement = this.$refs.contact
            const targetRect = mobileReferenceElement.getBoundingClientRect()
            const targetY = targetRect.top
            this.$scrollmagic.scrollTo(targetY)
          }
        }
      })
    }
  },
  mounted () {
    let scope = this
    scope.scrollIntoView()
  },
  components: {
  }
}
</script>

<style lang="scss" scoped>

.link-anchor {
  width: 100%;
}

</style>
