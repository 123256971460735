<template>
  <div class="container">
    <h2>{{ brands.heading }}</h2>
    <div class="list">
      <div
        class="brand"
         v-for="(brand, index) in brands.brands"
         :key="'brand'+index"
         :v-aspect-ratio="1"
      >
        <img
          :v-aspect-ratio="1"
          :src="brand.image.url"
          :alt="brand.image.alt"
        >
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    brands: Object
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/variables";
@import "../styles/container";
@import "../styles/grid";
@import "../styles/text";
@import "../styles/theme";

.container {
  @extend %container;
  @extend %mobile-container;
  // margin-top: 172px;
  /* margin-bottom: calc(165px - #{d-grid(0.1)}); */
  /* @media (max-width: 800px) { */
    /* margin-bottom: 93px; */
  /* } */

  .list {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: d-grid(0.90);
    padding-right: grid-pct(0.90);
    .brand {
      display: flex;
      justify-content: center;
      align-items: center;
      @include box;

      width: d-grid(2);
      padding: d-grid(0.2);
      margin-bottom: d-grid(0.1);

      @media (max-width: 1024px) {
        width: m-grid(2);
        padding: m-grid(0.26);
        margin-bottom: 0px;
      }
      img {
        width: 100%;
      }
    }
  }
  h2 {
    margin-left: d-grid(1);
    margin-right: grid-pct(1);
    margin-top: 0px;
    margin-bottom: 69px - 43px;
    font-size: 2.25rem;
    font-weight: normal;
    letter-spacing: -.01em;
    @include t3;
    @media (max-width: 800px) {
      @include m-t3;
      margin-bottom: 24px;
    }
  }
}
@include theme('light') {
  img {
    @include theme-transition;
    filter: invert(100%);
  }
}
</style>
